<template>
    <div class="customers-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">КЛИЕНТИ</h4>
                    <p class="text-white mx-5 my-4">През годините изградихме успешни връзки с много бизнеси и частни клиенти. За всеки от тях създадохме персонален план и схема на работа. Тяхната висока оценка за услугите ни е нашето най-голямо постижение.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomersCover",
    }
</script>