<template>
  <div class="customers container-fluid px-0">
    <CustomersCover />
    <div class="clients container py-5">
      <h3 v-show="test" class="text-center my-5 py-5">Клиенти, които ни се довериха.</h3>
      <!--  <hr>
         <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-4 customers-list">
                    <ul>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-4 customers-list">
                    <ul>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-4 customers-list">
                    <ul>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                        <li>Special Customer Name</li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
  import CustomersCover from '../components/includes/customers/CustomersCover'

  export default {
    name: 'Customers',
    components: {
      CustomersCover,
    }
  }
</script>
